/** @jsx jsx */
import { jsx, Text } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from './layout';
import Title from './title';
import Listing from './listing';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import useSiteMetadata from '../hooks/use-site-metadata';
import replaceSlashes from '../utils/replaceSlashes';
import { visuallyHidden } from '../styles/utils';

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
  [key: string]: any;
};

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig();
  const { siteTitle } = useSiteMetadata();

  return (
    <Layout>
      <h1>
        <Text sx={visuallyHidden}>{siteTitle}</Text>
      </h1>
      <Title text="最近の記事" sx={{ mt: 4 }}>
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
          すべての記事を表示
        </Link>
      </Title>
      <Listing posts={posts} showTags={false} />
    </Layout>
  );
};

export default Homepage;
